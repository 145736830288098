import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { BaseChartDirective } from 'ng2-charts';
import { AccordionComponent } from './components/accordion/accordion.component';
import { BasicLayoutComponent } from './layouts/basic-layout/basic-layout.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ButtonComponent } from './components/button/button.component';
import { CardComponent } from './components/card/card.component';
import { CardBankComponent } from './components/card/components/card-bank/card-bank.component';
import { ChartComponent } from './components/chart/chart.component';
import { ContentComponent } from './layouts/content/content.component';
import { ContentDetailComponent } from './layouts/content-detail/content-detail.component';
import { ContentHeaderComponent } from './layouts/content-header/content-header.component';
import { ContentMainComponent } from './layouts/content-main/content-main.component';
import { ContentSideNavComponent } from './layouts/content-side-nav/content-side-nav.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { ErrorSnackBarComponent } from './components/error-snack-bar/error-snack-bar.component';
import { FileUploadButtonComponent } from './components/file-upload-button/file-upload-button.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { GlobalHoverDirective } from './directives/global-hover.directive';
import { GridColsDirective } from './directives/grid-cols.directive';
import { GridLayoutAltComponent } from './layouts/grid-layout-alt/grid-layout-alt.component';
import { GridLayoutComponent } from './layouts/grid-layout/grid-layout.component';
import { IconComponent } from './components/icon/icon.component';
import { InputComponent } from './components/input/input.component';
import { ListElementComponent } from './layouts/list-element/list-element.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalControllerComponent } from './components/modal-controller/modal-controller.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { SideNavComponent } from './layouts/side-nav/side-nav.component';
import { SidenavHoverDirective } from './directives/sidenav-hover.directive';
import { SidenavLayoutComponent } from './layouts/sidenav-layout/sidenav-layout.component';
import { TableComponent } from './components/table/table.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TagComponent } from './components/tag/tag.component';
import { TopBarComponent } from './layouts/top-bar/top-bar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UploadModalComponent } from './components/file-upload-button/components/upload-modal/upload-modal.component';
import { ResultSnackBarComponent } from './components/result-snack-bar/result-snack-bar.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { RedmineComponent } from './components/redmine/redmine.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ClockComponent } from './components/clock/clock.component';

@NgModule({
  declarations: [
    AccordionComponent,
    BasicLayoutComponent,
    BreadcrumbComponent,
    ButtonComponent,
    CardComponent,
    CardBankComponent,
    ChartComponent,
    ContentComponent,
    ContentDetailComponent,
    ContentHeaderComponent,
    ContentMainComponent,
    ContentSideNavComponent,
    DragAndDropDirective,
    DropdownMenuComponent,
    ErrorSnackBarComponent,
    FileUploadButtonComponent,
    FileViewerComponent,
    GlobalHoverDirective,
    GridColsDirective,
    GridLayoutAltComponent,
    GridLayoutComponent,
    IconComponent,
    InputComponent,
    ListElementComponent,
    ModalControllerComponent,
    SideNavComponent,
    SidenavHoverDirective,
    SidenavLayoutComponent,
    TableComponent,
    TabsComponent,
    TagComponent,
    TopBarComponent,
    SpinnerComponent,
    UploadModalComponent,
    ResultSnackBarComponent,
    PaginationComponent,
    RedmineComponent,
    ClockComponent
  ],
  imports: [
    BaseChartDirective,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    PdfJsViewerModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule
  ],
  exports:[
    AccordionComponent,
    BasicLayoutComponent,
    ButtonComponent,
    CardComponent,
    CardBankComponent,
    ChartComponent,
    ContentComponent,
    ContentDetailComponent,
    ContentHeaderComponent,
    ContentMainComponent,
    ContentSideNavComponent,
    DropdownMenuComponent,
    FileUploadButtonComponent,
    FileViewerComponent,
    GridColsDirective,
    GridLayoutAltComponent,
    GridLayoutComponent,
    IconComponent,
    InputComponent,
    ListElementComponent,
    ModalControllerComponent,
    SideNavComponent,
    SidenavHoverDirective,
    SidenavLayoutComponent,
    SpinnerComponent,
    TableComponent,
    TabsComponent,
    TagComponent,
    TopBarComponent,
    TranslateModule,
    PaginationComponent,
    ClockComponent
  ]
})

export class SharedModule { }
